<template>
    <div class="business-new-feedback" v-if="!creationContainer.isCustomQuestionsOpen">
        <div class="business-new-feedback__header">
            <button class="header-back-btn" @click="creationContainer.prevStep()">
                <inline-svg src="/media/buying/icons/arrow-right.svg" />
                <span>{{ creationContainer.currentStep === BFeedbackStepsEnum.SET_BASIC_INFO ? $t('Cancel') : $t('Back') }}</span>
            </button>
            <div class="business-new-feedback__header--step">
                <span class="text-gray">
                    {{ $t("Step") + " " + creationContainer.currentStep + " " + $t("of") + " " + creationContainer.stepsCount }}
                </span>
                <div class="demo-progress step-progress w-100">
                    <el-progress class="w-100" :stroke-width="4" :percentage="creationContainer.stepsPercentage" />
                </div>
                <div class="step-progress"></div>
            </div>
        </div>

        <!-- Step 1 -->
        <BusinessStepOneBasicInfo
            v-if="creationContainer.currentStep === BFeedbackStepsEnum.SET_BASIC_INFO"
            :creationContainer="creationContainer"
        />

        <!-- Step 2 -->
        <BusinessStepTwoDescriptionDeadline
            v-if="creationContainer.currentStep === BFeedbackStepsEnum.SESSION_DESCRIPTION"
            :creationContainer="creationContainer"
        />

        <!-- Step 3 -->
        <BusinessStepThreeQuestionnaire
            v-if="creationContainer.currentStep === BFeedbackStepsEnum.SET_QUESTIONNAIRE"
            :creationContainer="creationContainer"
        />

        <!-- Step 4 -->
        <BusinessStepFourSelectBanks
            v-if="creationContainer.currentStep === BFeedbackStepsEnum.SELECT_BANKS"
            :creationContainer="creationContainer"
        />

        <!-- Step 5 -->
        <BusinessStepFiveSessionSummary
            v-if="creationContainer.currentStep === BFeedbackStepsEnum.SESSION_SUMMARY"
            :creationContainer="creationContainer"
        />
    </div>

    <div class="business-new-feedback" v-else>
        <CustomQuestionsContainer
            :questionsContainer="creationContainer.customQuestionsData"
            @handleBack="handleBack"
            @continueWithSelectedQuestions="onContinueWithSelectedQuestions"
        />
    </div>
</template>

<script>
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import {
    BFeedbackStepsEnum,
    BusinessFeedbackCreationContainer
} from "@/store/models/business/feedback/BusinessFeedbackCreationContainer";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import BusinessStepOneBasicInfo from "@/buying-teams/pages/business/feedbacks/feedbacks-creation/BusinessStepOneBasicInfo";
import BusinessStepTwoDescriptionDeadline from "@/buying-teams/pages/business/feedbacks/feedbacks-creation/BusinessStepTwoDescriptionDeadline";
import BusinessStepThreeQuestionnaire from "@/buying-teams/pages/business/feedbacks/feedbacks-creation/BusinessStepThreeQuestionnaire";
import BusinessStepFourSelectBanks from "@/buying-teams/pages/business/feedbacks/feedbacks-creation/BusinessStepFourSelectBanks";
import BusinessStepFiveSessionSummary from "@/buying-teams/pages/business/feedbacks/feedbacks-creation/BusinessStepFiveSessionSummary";
import CustomQuestionsContainer from "@/buying-teams/shared-components/custom-questions/CustomQuestionsContainer";
import Swal from "sweetalert2/dist/sweetalert2.js";
import store from "@/store";
import { BusinessUserPermissionTypeEnum } from "@/store/enums/business/BusinessUserPermissionTypeEnum";

export default {
    name: "BusinessFeedbackCreate",
    components: {
        CustomQuestionsContainer,
        BusinessStepFiveSessionSummary,
        BusinessStepFourSelectBanks,
        BusinessStepThreeQuestionnaire,
        BusinessStepTwoDescriptionDeadline,
        BusinessStepOneBasicInfo,
        CustomLoader,
    },
    data() {
        return {
            BFeedbackStepsEnum,
            creationContainer: new BusinessFeedbackCreationContainer(),
        }
    },
    computed: {
        authUser() {
            return store.getters.currentUser;
        }
    },
    mounted() {
        if (this.authUser && !this.authUser.can(BusinessUserPermissionTypeEnum.CREATE_FEEDBACK_SESSION)) {
            this.$router.push('/bank/feedback/feedback-sessions');
        }

        setCurrentPageBreadcrumbs({
            title: this.$t('New Feedback session'),
            subTitle: this.$t('Create your own feedback sessions')
        });
    },
    methods: {
        handleBack() {
            Swal.fire({
                title: this.$t('Cancel and back to step NUM', { n: 3 }),
                text: this.$t('Are you sure you want to go back to step NUM, the selected questions will be discarded', { n: 3 }),
                showCancelButton: true,
                confirmButtonText: this.$t('Discard changes'),
                customClass: 'cancel-and-back',
                cancelButtonText: this.$t('Cancel')
            }).then((result) => {
                if (result.value) {
                    this.creationContainer.isCustomQuestionsOpen = false;
                }
            })
        },
        onContinueWithSelectedQuestions() {
            this.creationContainer.setSelectedCustomQuestions();
            this.creationContainer.isCustomQuestionsOpen = false;
        }
    }
}
</script>

<style lang="scss">
.business-new-feedback {
    margin-top: 20px;
    background: #FFFFFF;
    box-shadow: 0 9px 29px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    padding: 20px 53px 48px 38px;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &--title {
            font-weight: 500;
            font-size: 18px;
            line-height: 170.02%;
            color: #4F4F4F;
        }
        &--step {
            .el-progress__text {
                display: none;
            }
            .text-gray {
                font-weight: 400;
                font-size: 18px;
                line-height: 178.52%;
                color: #434343;
                opacity: 0.6;
            }
        }
    }
    .header-back-btn {
        font-weight: 500;
        border: none;
        font-size: 18px;
        line-height: 170.02%;
        color: #4F4F4F;
        background: transparent;
        display: flex;
        align-items: center;
        gap: 10px;
        svg {
            transform: rotate(-180deg);
        }
        path {
            stroke: #4F4F4F;
        }
    }
    .continue-button {
        width: 334px;
        height: 48px;
        @media (max-width: 400px) {
            width: 100%;
        }
    }

    @media (max-width: 600px) {
        padding: 20px 24px;
    }
}
</style>
