import { BusinessBankModel } from "@/store/models/BusinessBankModel";

export class BusinessSelectBanksContainer {
    allBanks: BusinessBankModel[];
    selectedBanks: Map<Number, BBCBankModel>;

    constructor(banks: any[]) {
        this.allBanks = banks.map(b => new BusinessBankModel(b));
        this.selectedBanks = new Map<number, BBCBankModel>();
    }

    get getSelectedBanks(): BBCBankModel[] {
        return Array.from(this.selectedBanks.values());
    }

    isBankSelected(bank: BusinessBankModel) {
        return Array.from(this.selectedBanks.values()).some(e => e.id === bank.id);
    }

    selectBank(bank: BusinessBankModel, selectedCountries: string[]) {
        if (!selectedCountries.length) {
            this.selectedBanks.delete(bank.id);
            return;
        }
        this.selectedBanks.set(bank.id, new BBCBankModel(
            bank,
            selectedCountries
        ));
    }

    deleteBank(bank: BusinessBankModel) {
        this.selectedBanks.delete(bank.id);
    }

    deleteAllSelectedBanks() {
        this.selectedBanks = new Map<number, BBCBankModel>();
    }

    getFilteredData(search: string): BusinessBankModel[] {
        let data: BusinessBankModel[] = this.allBanks;
        return search ? data.filter((item: BusinessBankModel) => {
            return item.name.toLowerCase().includes(search.toLowerCase());
        }) : data;
    }
}

export class BBCBankModel extends BusinessBankModel {
    selectedCountries: string[] = [];

    constructor(data: any, selectedCountries) {
        super(data);
        this.selectedCountries = selectedCountries;
    }
}
